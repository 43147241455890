import React from "react";
import PyPlCheckoutBtn from "./PyPlCheckoutBtn";
const Checkout = () => {
  return (
    <div>
      <h1>checkout</h1>
      <PyPlCheckoutBtn />
    </div>
  );
};

export default Checkout;
