// Super.js

import React from "react";

const Super = () => {
  return (
    <div>
      <h1>shop com</h1>
    </div>
  );
};

export default Super;
